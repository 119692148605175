import logo from "./logo.svg";
import "./App.css";
import { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import HomeScreen from "./Pages/HomeScreen/Homescreen";
import ServiceScreen from "./Pages/Services/Services";
import Partnerships from "./Pages/PartnerShips/partnerships.js";
import MediaNews from "./Pages/MediaNews/MediaNews";
import OurStory from "./Pages/OurStory/OurStory.js";
import Menu from "./Components/Menu/Menu.js";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/services" element={<ServiceScreen />} />
          <Route path="/partner" element={<Partnerships />} />
          <Route path="/medianews" element={<MediaNews />} />
          <Route path="/ourstory" element={<OurStory />} />
          <Route path="/menu" element={<Menu />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
