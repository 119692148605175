import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./Header.module.css";
import PGALogo from "../../Images/PGA Bharat Logo 2.png";
import searchIcon from "../../Images/search.png";
import downArrow from "../../Images/chevron-down.png";
import Menu from "../../Components/Menu/Menu.js";
import burger from "../../Images/hamburger.png";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const divRef = useRef(null);

  const [menuOpened, setMenuOpened] = useState(false);

  const handleClickOutside = useCallback((event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setMenuOpened(false);
    }
  }, []);

  useEffect(() => {
    if (menuOpened) {
      window.addEventListener("mousedown", handleClickOutside);
    } else {
      window.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpened, handleClickOutside]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.leftheader}>
          <img src={PGALogo} className={classes.logoimg} alt="Logo" />
          {window.innerWidth > 768 && (
            <div className={classes.searchbar}>
              <img
                src={searchIcon}
                alt="Search Icon"
                className={classes.searchIcon}
              />
              <input
                type="text"
                placeholder="Search here"
                className={classes.searchInput}
              />
              <button className={classes.exploreButton}>
                Explore
                <img
                  src={downArrow}
                  alt="Down Arrow"
                  className={classes.downArrowIcon}
                />
              </button>
            </div>
          )}
        </div>
        {window.innerWidth > 768 && (
          <div className={classes.navitems}>
            <div
              onClick={() => navigate("/")}
              className={`${classes.navtext} ${
                location.pathname === "/" ? classes.navtext1 : ""
              }`}
            >
              HOME
            </div>
            <div
              onClick={() => navigate("/ourstory")}
              className={`${classes.navtext} ${
                location.pathname === "/ourstory" ? classes.navtext1 : ""
              }`}
            >
              ABOUT
            </div>
            <div
              onClick={() => navigate("/medianews")}
              className={`${classes.navtext} ${
                location.pathname === "/medianews" ? classes.navtext1 : ""
              }`}
            >
              MEDIA AND NEWS
            </div>
            <div
              onClick={() => navigate("/services")}
              className={`${classes.navtext} ${
                location.pathname === "/services" ? classes.navtext1 : ""
              }`}
            >
              SERVICES
            </div>
            <div
              onClick={() => navigate("/partner")}
              className={`${classes.navtext} ${
                location.pathname === "/partner" ? classes.navtext1 : ""
              }`}
            >
              PARTNERS
            </div>
          </div>
        )}
        {window.innerWidth <= 768 && (
          <div onClick={() => setMenuOpened(true)}>
            <img
              src={burger}
              className={classes.burgerbutton}
              alt="Menu Icon"
            />
          </div>
        )}
        {menuOpened && (
          <>
            <div className={classes.blurOverlay} />
            <div
              ref={divRef}
              className={classes.menu}
              onClick={() => setMenuOpened(false)}
            >
              <Menu />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
