import React from 'react'
import classes from './keyMembers.module.css'

const KeyMembers = () => {
  return (
    <div className={classes.keyMembersContainer}>
        <div className={classes.h4}>Key Club Member Services</div>
        <div className={classes.keyMembersDesc}>At PGA Bharat, we strive to offer our members a truly exceptional experience. Our personalized membership plans and club services are designed to cater to the needs and preferences of every golfer, ensuring a rewarding and enjoyable journey. We ensure that every member receives the highest level of service and care. Below are the key services we offer to enhance the overall member experience.</div>
        <div className={classes.aboutContainer}>
            <div className={classes.golferImage}></div>
            <div className={classes.aboutList}>
                <div className={classes.aboutText}>
                <b>Personalized Membership Plans:</b> We offer a variety of customized membership packages tailored to suit the needs of different members, from casual players to elite golfers. These plans include exclusive benefits such as priority tee times, access to private events, and discounts on services, creating a personalized and valuable experience for every member.
                </div>
                <div className={classes.aboutText}>
                <b>Member Loyalty Programs:</b> Our club member loyalty programs are designed to reward regular participation and engagement. Members can accumulate points for every round played, referral of new members, or participation in club events, which can then be redeemed for rewards such as discounted fees, pro shop credits, or exclusive experiences.
                </div>
                <div className={classes.aboutText}>
                <b>Technology-Driven Member Management:</b> We utilize advanced member management software to streamline all aspects of club operations. From tracking member preferences to automating communication about upcoming events, tee time reservations, and billing, our systems ensure that members receive personalized attention and care.
                </div>
                <div className={classes.aboutText}>
                <b>Clubhouse Operations & Amenities Management:</b> Beyond the golf course, we offer full-service clubhouse management, including dining, event planning, and amenities such as spas, fitness centers, and swimming pools. Our team ensures that all facilities are maintained to the highest standards, delivering a luxury experience to members and their guests.
                </div>
                <div className={classes.aboutText}>
                <b>Exclusive Member Events & Tournaments:</b> To foster a sense of community, we organize exclusive events and tournaments for club members. These include friendly competitions, themed social gatherings, and VIP experiences with professional golfers, providing members with engaging and unique opportunities to enjoy their club.
                </div>
                <div className={classes.aboutText}>
                <b>Customer Service Excellence:</b> Our professional staff is trained to provide top-tier customer service, ensuring that every interaction with members exceeds expectations. From welcoming members to addressing their needs efficiently and with a personal touch, our team is dedicated to enhancing the overall member experience.
                </div>
                <div className={classes.aboutText}>
                <b>Feedback and Engagement Tools:</b> To continually improve services, we provide members with easy-to-use feedback and engagement tools, allowing them to share their experiences, suggest improvements, and participate in surveys. This open line of communication helps us to adapt and evolve our services according to member needs.
                </div>
            
            </div>
        </div>
    </div>
  )
}

export default KeyMembers