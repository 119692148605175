import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Menu.module.css";
import menuIcon from "../../Images/hamburger.png";

const MainContent = () => {
  const navigate = useNavigate();

  const menuItems = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/ourstory" },
    { label: "Media & News", path: "/medianews" },
    { label: "PartnerShips", path: "/partner" },
    { label: "Services", path: "/services" },
  ];

  return (
    <div>
      <div className={classes.menu}>
        <div className={classes.menuHeader}>
          <h2>MENU</h2>
          <img src={menuIcon} alt="Menu Icon" className={classes.menuIcon} />
        </div>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index} onClick={() => navigate(item.path)}>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MainContent;
